<script>
import axios from 'axios';
import {login} from "@/auth/login.mixin.js";
export default {
  name: "login-form-min",

  data() {
    return {
      email: '',
      password: '',
      remember: false,
      errors: {}
    };
  },
  methods: {
    async loginHandler() {
      try {
        await login(this.email, this.password, this.remember);
      } catch (errors) {
        this.errors = errors;
      }
    }
  }
};
</script>
<template>
  <div class="w-full bg-white border-2 border-grey-200 md-minus:px-4 lg-plus:px-8 py-4 rounded-lg">
    <form @submit.prevent="loginHandler">
      <div :class="{'has-error': errors.email}" class="flex flex-col">
        <label for="email" class="text-base text-grey-500">Benutzername</label>
        <input id="email" v-model="email" type="text" class="input-light pl-2" autocomplete="username" required autofocus>
        <span v-if="errors.email" class="help-block danger">{{ errors.email }}</span>
      </div>

      <div :class="{'has-error': errors.password}" class="flex flex-col mt-4">
        <label for="password" class="text-base text-grey-500">Passwort</label>
        <input id="password" v-model="password" type="password" class="input-light pl-2" autocomplete="current-password" required>
        <span v-if="errors.password" class="help-block danger">{{ errors.password }}</span>
      </div>

      <div class="flex flex-row w-full items-center justify-between text-sm py-2">
        <label class="w-full flex flex-row justify-start items-center cursor-pointer hover:text-grey-500">
          <input type="checkbox" v-model="remember" class="" value="1"> <span class="inline-block pl2">Eingeloggt bleiben</span>
        </label>
        <a class="inline-block text-right w-full" href="/password/reset">
          Passwort vergessen?
        </a>
      </div>

      <div class="w-full flex flex-row justify-end flex-wrap text-base mt-3 w100">
        <a class="border-2 border-secondary_color my-2 mr-2 hover:border-secondary-900 rounded flex flex-row items-center p-1" href="/register">
          <i class="fas fa-user-plus mr-2"></i> <span class="inline-block">Registrieren</span>
        </a>
        <button type="submit" class="button-red p-1 px-5 my-2 ml-2 text-base flex flex-row justify-center items-center auth-menu_login-button rounded">
          <hermeneus-icon name="hi-login" width="25" height="25" color="hi-color-white"></hermeneus-icon>
          <span class="ml-2 text-lg">Einloggen</span>
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>