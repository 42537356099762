import {defineStore} from 'pinia'
import StepData from "../model/StepData.js";

export const msConfigStore = defineStore('msConfigStore', {
    state: () => ({
        steps: [] // Instanzen der Klasse StepData einfügen. NICHT DIREKT MODIFIZIEREN!!! Immer action benutzen!
    }),

    actions: {
        addStep(step) {
            if (! step instanceof StepData) throw new TypeError('Step muss eine Instanz von StepData sein!');
            let isUpdate = false;
            this.steps.forEach((s, index) => {
                if (s.id === step.id) {
                    this.steps[index] = step;
                    isUpdate = true;
                }
            });
            if (!isUpdate) {
                this.steps.push(step);
            }
        }
    }
})