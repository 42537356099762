<template>
  <div class="w-auto">
    <ul class="navi-mobile__sub">
      <template v-for="(index, key, link) in links">
        <li v-if="hasVisibleSubnavi(links[key])">
          <button @click.stop="toggleActiveCategory(key)" class="navi-mobile__sub-button capitalize flex flex-col text-2xl">
            <div class="p-4 flex flex-row items-center">
              <i v-if="activeCategory === key" class="fa-solid fa-angle-down mr-3"></i>
              <i v-else class="fa-solid fa-angle-right mr-3"></i>
              <div>
                {{ key }}

              </div>
            </div>
            <template v-if="activeCategory=== key">
              <a v-for="categorylink in links[key]" v-show="showLink(categorylink)" :href="categorylink.url"
                 class="w-full py-4 pl-6 font-light bg-primary-700 text-left text-primary-200">{{ categorylink.titel }}</a>
            </template>
          </button>
        </li>
      </template>
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "navi-mobile-hamburger",
  props: ['links'],
  data: function () {
    return {
      activeCategory: null
    }
  },
  methods: {
    toggleActiveCategory (category) {
      if (category === this.activeCategory) {
        this.activeCategory = null
      } else {

        this.activeCategory = category;
      }
    },
    /**
     * Ist für den aktuell eingeloggten Nutzer überhaupt ein Menüpunkt der Subnavi sichtbar oder sieht er gar nichts?
     * @param category
     */
    hasVisibleSubnavi (category) {
      return category.filter(subcategory => {
        return (subcategory.hasOwnProperty('show') && subcategory.show === true) || !subcategory.hasOwnProperty('show');
      }).length > 0;
    },
    showLink (link) {
      if (link.hasOwnProperty('show')) {
        return link.show
      }
      return true;
    },
    customHTML (link) {
      return link.hasOwnProperty('customHTML');
    }
  }
}
</script>

<style lang="scss" scoped>
.navi-mobile__sub {
  @apply flex;
  @apply flex-col;
  @apply bg-primary-900;
  @apply text-xl;
}

*.navi-mobile__sub-button {
  @apply text-primary-300;
  @apply w-full;
}

*.navi-mobile__sub-button:hover {
  @apply text-primary-200;
  @apply bg-primary-800;
}

</style>