import {singleDeleteRequestWith} from "@/glossarium/functions.js";
import {EventBus} from "@/hermeneus.eventbus/eventbus.js";
import axios from 'axios';
export let interactsWithGlossariumMixin = {
    methods: {

        /**
         * Opens VocabCreator.vue with open-modal-component
         */
        openVocabCreator () {
            let ModalData = {
                title: 'Neue Vokabel erstellen',
                type: 'success',
                size: 'fullscreen',
                component: 'glossarium-vocab-creator',
                uri: '/glossarium/create',
                appearance: {"footer": false, body: 'bg-grey-100'},
            };

            EventBus.$emit('OpenModalEvent', JSON.stringify(ModalData));
        },

    },
    mounted: function () {


        EventBus.$on('openVocabEditor', value => {
            this.openVocabEditor(value);
        })
        EventBus.$on('openVocabEditorParams', value => {
            this.openVocabEditorParams(value.wortart, value.id);
        })
        EventBus.$on('deleteVocabConfirm', value => {
            this.singleDeleteConfirm(value);
        })



    },
}