<script>
import {toInteger} from "lodash";

export default {
  name: "primevue-hermeneus-preview",
  methods: {toInteger},
  data() {
    return {
      inputswitch: true,
      checkbox: true,
      dropdown_selected: "2",
      dropdown_selected2:"1",
      dropdownoptions: [
        {"name": "null", "value": "0"},
        {"name": "eins", "value": "1"},
        {"name": "zwei", "value": "2"},
        {"name": "drei", "value": "3"},
      ],
      calendar_date: null,
      radio_button_selection: "nix",
    };
  },
}
</script>

<template>
  <h2 id="Divider">Divider</h2>
  <Divider></Divider>

  <h2 id="Paginator">Paginator</h2>
  <Paginator
      :totalRecords="20"
      :rows="2"
      :page-link-size="5"
      :pt="{root: '!justify-end !px-0' }"
      template="FirstPageLink PrevPageLink   NextPageLink LastPageLink"
      currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
  ></Paginator>

  <h2 id="Button">Button</h2>
  <div class="flex flex-row justify-evenly items-start">

    <Button severity="grey" icon="fas fa-trash" label="Entfernen"></Button>
    <Button label="Speichern" severity="secondary" icon="bx bxs-cloud-upload"></Button>
    <Button label="Speichern" outlined severity="secondary" icon="bx bxs-cloud-upload"></Button>
    <Button label="Danger" outlined severity="danger" icon="bx bxs-cloud-upload"></Button>
    <Button label="Groß" outlined severity="secondary" size="large" icon="bx bxs-cloud-upload"></Button>
    <Button label="ExtraGroß" outlined severity="secondary" size="xl" icon="bx bxs-cloud-upload"></Button>
    <Button label="ExtraExtraGroß" outlined severity="secondary" size="xxl" icon="bx bxs-cloud-upload"></Button>
  </div>


  <h2 id="InputSwitch">InputSwitch</h2>
  <InputSwitch v-model="inputswitch"></InputSwitch>

  <h2 id="ProgressBar">ProgressBar</h2>
  <ProgressBar mode="indeterminate"></ProgressBar>
  <h2 id="ProgressBar">Checkbox</h2>
  <Checkbox v-model="checkbox" binary></Checkbox>
  <h2 id="Dropdown">Dropdown</h2>
  <div class="flex flex-row justify-between">
  <Dropdown v-model="dropdown_selected" option-label="name" option-value="value" :options="dropdownoptions" placeholder="Bitte auswählen"></Dropdown>
  Inline-Options:
  <Dropdown v-model="dropdown_selected2" option-label="name" option-value="value" :options='[
        {"name": "null", "value": "0"},
  {"name": "eins", "value": "1"},
  {"name": "zwei", "value": "2"},
  {"name": "drei", "value": "3"},
  ]' placeholder="Bitte auswählen">


  </Dropdown>



  </div>
  <h2 id="Calendar">Calendar</h2>

  <Calendar id="calendar-24h" dateFormat="dd.mm.yy' um '" v-model="calendar_date" showTime hourFormat="24" show-icon />
  <h2 id="RadioButton">RadioButton</h2>
  <div class="flex flex-wrap gap-3">
    <div class="flex items-center">
      <RadioButton v-model="radio_button_selection" inputId="ingredient1" name="nix" value="nix" />
      <label for="ingredient1" class="ml-2">Nix</label>
    </div>
    <div class="flex items-center">
      <RadioButton v-model="radio_button_selection" inputId="ingredient2" name="Hoho" value="hoho" />
      <label for="ingredient2" class="ml-2">Hoho</label>
    </div>
    <div class="flex items-center">
      <RadioButton v-model="radio_button_selection" inputId="ingredient3" name="Hoho" value="hihi" />
      <label for="ingredient3" class="ml-2">Hihi</label>
    </div>
  </div>
</template>

<style scoped>
h2 {
  margin-top:
      4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
}
</style>