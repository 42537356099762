<template>
    <div class="collapsible w-full" :class="main_classes">
        <div @click.prevent="toggleCollapse"
             :class="classCollapsed"
             class="collapsible__title p-3 flex flex-row justify-between cursor-pointer">
            <div>
                <slot name="title"></slot>
            </div>
            <div class="w-6">
                <i class="fas fa-angle-down"></i>
            </div>
        </div>
        <transition name="slideY">
            <div v-if="!isCollapsed" class="collapsible__body" :class="body_classes">
                <slot name="body"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "hermeneus-collapsible",
        props: {
            collapsed: {
                type: Boolean,
                required: false,
                default: true,
            },
            color: {
                type: String,
                required: false,
                default: 'primary'
            },
            main_classes: {
                type: String,
                required: false,
                default: 'my-6'
            },
            body_classes: {
                type: String,
                required: false,
                default: 'p-6 bg-primary-200'
            },
            size: {
                type: String,
                required: false,
                default: '3xl'
            },
        },
        data: function () {
            return {
                isCollapsed: this.collapsed,
            }

        },
        computed: {
            classCollapsed: function () {
                return {
                    [`text-${this.size}`]: true,
                    [`bg-${this.color}_color  text-white rounded-t-md`]: !this.isCollapsed,
                    [`bg-${this.color}-200 text-${this.color}_color  hover:bg-${this.color}_color hover:text-white rounded-md`]: this.isCollapsed,
                }
            }
        },
        methods: {
            toggleCollapse () {
                this.isCollapsed = !this.isCollapsed;
            }
        }
    }
</script>

<style scoped>

    .collapsible__title {
        transition: all 0.25s ease;
    }



    .slideY-enter-active, .slideY-leave-active {
        transition: all 0.25s ease;
    }

    .slideY-enter, .slideY-leave-to {
        opacity: 0;
        transform: translateY(-50%) scaleY(0) !important;
    }
</style>
