// authService.js
import axios from 'axios';
import Cookies from 'js-cookie';

export async function login(email, password, remember) {
    try {
        const response = await axios.post('/login', {
            email: email,
            password: password,
            remember: remember
        });

        localStorage.setItem('auth_token', response.data.auth_token);

        window.location.href = '/schreibtisch';
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            throw error.response.data.errors || {};
        }
    }
}
