<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import {login} from "@/auth/login.mixin.js";

export default {
  name: "login-form",
  data () {
    return {
      email: '',
      password: '',
      remember: false,
      errors: {}
    }
  },
  methods: {
    async loginHandler() {
      try {
        await login(this.email, this.password, this.remember);
      } catch (errors) {
        this.errors = errors;
      }
    }
  }
}
</script>

<template>
  <div class="user_form bg-white">
    <form @submit.prevent="loginHandler">
      <div :class="{'has-error': errors.email}" class="flex-column">
        <label for="email" class="label_top secondary mb1">E-Mail-Adresse / Benutzername</label>
        <input id="email" v-model="email" type="text" class="input-light pl2" autocomplete="username" required autofocus>
        <span v-if="errors.email" class="help-block danger">{{ errors.email.join(' ') }}</span>
      </div>

      <div :class="{'has-error': errors.password}" class="flex-column mt4">
        <label for="password" class="label_top secondary mb1">Passwort</label>
        <input id="password" v-model="password" type="password" class="input-light pl2" autocomplete="current-password" required>
        <span v-if="errors.password" class="help-block danger">{{ errors.password.join(' ') }}</span>
      </div>
      <a class="addendum inline-block my-2 bold w100 text-right" href="/password/reset">
        Passwort vergessen?
      </a>

      <div class="checkbox w100 flex-group-end mt2">
        <label class="w-full flex flex-row justify-end items-center cursor-pointer hover:text-grey-500">
          <input type="checkbox" v-model="remember" class="" value="1">
          <span class="inline-block pl2">Eingeloggt bleiben</span>
        </label>
      </div>
      <div class="w100 flex-group-end mt6 w100">
        <button type="submit" class="button-red p-2 pl5 pr5 text-base flex flex-row justify-center items-center auth-menu_login-button w100 rounded">
          <hermeneus-icon name="hi-login" width="25" height="25" color="current"></hermeneus-icon>
          <span class="ml-2 text-lg">Einloggen</span>
        </button>
      </div>
    </form>

    <div class="auth-menu__register mt-3 flex-group-end w100">
      <form action="/register" method="get" class="w100">
        <button class="button-frame text-base flex flex-row justify-center items-center p-2 secondary w100 rounded">
          <hermeneus-icon name="hi-signup" width="25" height="25" color="current"></hermeneus-icon>
          <span class="secondary ml-2 text-lg">Registrieren</span>
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>