<template>
    <div class="flex-group-center">
        <loading-dots :color="'red'" id="results-loader" v-if="isLoading"></loading-dots>
        <div class="secondary text-base italic"><slot v-if="isLoading" ></slot></div>
    </div>
</template>

<script>
    import throttle from 'lodash.throttle';

    export default {
        name: "load-more",
        props: {
            container: {}
        },
        data: function () {
            return {
                'isLoading': false
            }
        },
        methods: {
            /**
             * Infinite-scroll functionality
             */
            loadMoreResults: throttle(function () {
                let container = document.querySelector(this.container);
                let resultsHeight = container.clientHeight;
                let resultsDistanceFromWindowTop = document.querySelector(this.container).offsetTop;
                this.isLoading = false;
                if (window.pageYOffset >= (resultsDistanceFromWindowTop + resultsHeight) * 0.5) {
                    this.isLoading = true;
                    this.$emit('ready');
                }
            }, 600),
        },
        mounted: function () {
            window.addEventListener('scroll', this.loadMoreResults);


        }
    }
</script>

<style scoped>
    #results-loader {
        margin: 0
    }
</style>