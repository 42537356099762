<template>
  <div @mouseleave="hideSubNavi" class="navi-desktop z-40 fixed w-full right-0 flex-row items-start justify-end">

    <div class="navi-desktop__list-container inline-block">
      <ul class="navi-desktop__ul flex flex-row justify-end items-start bg-weiss-75 text-secondary_color sm:text-sm lg-plus:text-base xl:text-lg">
        <!--LOGO-->
        <li class="navi-desktop__li navi-desktop__logo"><a href="/">
          <hermeneus-icon :name="'hi-hermeneus'" width="38" height="38" class="fill-secondary p-1"></hermeneus-icon>
        </a></li>
        <!--SCHREIBTISCH: Wird nicht angezeigt, wenn alle Objekte des Arrays this.menu.schreibtisch die Eigenschaft show: false haben -->

        <li class="navi-desktop__li" v-show="this.menu.schreibtisch.some(subMenu => subMenu.show !== false)">
          <a @mouseover="toggleSubNavi($event, 'schreibtisch')" class="navi-desktop__a has-subnavi" href="#">Schreibtisch <i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="1" :links="this.menu.schreibtisch" v-if="activeSubNavi === 'schreibtisch'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--LERNINHALTE-->
        <li class="navi-desktop__li" v-show="this.menu.lerninhalte.some(subMenu => subMenu.show !== false)">
          <a @mouseover="toggleSubNavi($event, 'lerninhalte')" class="navi-desktop__a has-subnavi" href="#">Lerninhalte <i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="2" :links="this.menu.lerninhalte" v-if="activeSubNavi === 'lerninhalte'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--WERKZEUGE-->
        <li class="navi-desktop__li" v-show="this.menu.werkzeuge.some(subMenu => subMenu.show !== false)">
          <a @mouseover="toggleSubNavi($event, 'werkzeuge')" class="navi-desktop__a has-subnavi" href="#">Werkzeuge <i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="3" :links="this.menu.werkzeuge" v-if="activeSubNavi === 'werkzeuge'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--DOKUMENTATION-->
        <li class="navi-desktop__li" v-show="this.menu.ressourcen.some(subMenu => subMenu.show !== false)">
          <a @mouseover="toggleSubNavi($event, 'ressourcen')" class="navi-desktop__a has-subnavi" href="#">Ressourcen <i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="4" :links="this.menu.ressourcen" v-if="activeSubNavi === 'ressourcen'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--SITE-->
        <li class="navi-desktop__li">
          <a @mouseover="toggleSubNavi($event, 'site')" class="navi-desktop__a has-subnavi " href="#">
            <span class="inline-block">Über hermeneus</span><i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="5" :links="this.menu.site" v-if="activeSubNavi === 'site'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--REDAKTION-->
        <li class="navi-desktop__li" v-if="hasVisibleSubnavi(this.menu.redaktion)">
          <a @mouseover="toggleSubNavi($event, 'redaktion')" class="navi-desktop__a has-subnavi " href="#">
            <span class="inline-block">Redaktion</span><i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="6" :links="this.menu.redaktion" v-if="activeSubNavi === 'redaktion'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--VERWALTUNG-->
        <li class="navi-desktop__li" v-if="hasVisibleSubnavi(this.menu.verwaltung)">
          <a @mouseover="toggleSubNavi($event, 'verwaltung')" class="navi-desktop__a has-subnavi " href="#">
            <span class="inline-block">Verwaltung</span><i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="7" :links="this.menu.verwaltung" v-if="activeSubNavi === 'verwaltung'" class="min-w-18r"></navi-desktop-sub>
          </transition>
        </li>
        <!--USER-->
        <li class="navi-desktop__li">
          <a @mouseover="toggleSubNavi($event, 'user')" class="navi-desktop__a has-subnavi  flex-row items-center" href="#">
            <hermeneus-icon name="hi-user" color="current" width="18" height="18"></hermeneus-icon>
            <span class="lg-plus:inline-block ml-2 md-minus:hidden" v-show="this.$userData">Benutzerbereich</span><i class="fas fa-angle-down caret"></i></a>
          <transition name="slide-fade">
            <navi-desktop-sub :key="8" :links="this.menu.user" v-if="activeSubNavi === 'user'" class="min-w-18r">
              <div v-show="this.$userData" class="navi-desktop__sub__bullet">
                <form action="/logout" method="post" class="navi-desktop__sub__link" >
                  <hermeneus-icon name="hi-logout" width="42" height="42" color="current" class="navi-desktop__sub-icon "></hermeneus-icon>
                  <input type="hidden" name="_token" value="{{csrf}}">
                  <button type="submit" name="ausloggen" value="ausloggen" class="">Ausloggen</button>
                </form>
              </div>
            </navi-desktop-sub>
          </transition>
        </li>

      </ul>
    </div>
    <!--WEBCODE-COMPONENT-->
    <div class="bg-secondary_color px-4">

      <webcode-navigator :height="43"></webcode-navigator>
    </div>
  </div>
</template>

<script>
import NaviBase from "./NaviBase.vue"
import NaviDesktopSub from "./NaviDesktopSub.vue"

export default {
  name: "navi-desktop",
  extends: NaviBase,
  components: {
    "navi-desktop-sub": NaviDesktopSub
  },
  data: function () {
    return {
      activeSubNavi: null,
    }
  },
  methods: {
    /**
     * Ist für den aktuell eingeloggten Nutzer überhaupt ein Menüpunkt der Subnavi sichtbar oder sieht er gar nichts?
     * @param category
     */
    hasVisibleSubnavi (category) {
      return category.filter(subcategory => {
        return (subcategory.hasOwnProperty('show') && subcategory.show === true) || !subcategory.hasOwnProperty('show');
      }).length > 0;
    }
  },
  mounted () {
console.log();
    window.addEventListener("scroll", function (e) {
      if (window.scrollY >= 300) {
        document.querySelector('.navi-desktop').classList.add('is-floating');

      } else {

        document.querySelector('.navi-desktop').classList.remove('is-floating');
      }
    });
  }

}
</script>

<style lang="scss" scoped>
.navi-desktop {
}

.navi-desktop__list-container {
  width: 100%;
}

.navi-desktop__li {
  min-height: 45px;
  @apply relative;
  @apply inline-block;


}

.navi-desktop__li:hover .navi-desktop__a.has-subnavi {
  @apply bg-primary-900;
  @apply text-white;

}

.navi-desktop__a {
  @apply flex;
  @apply p-3;
}

/**
 * CARET
 */
.navi-desktop__a.has-subnavi .caret {
  padding: 0.25rem;
  margin-left: 0.25rem;
}

/**
* FLOATING NAV
*/

@keyframes slide-nav-down {
  100% {
    transform: translateY(0);
  }
}

.navi-desktop.is-floating {
  top: 0;
  animation: slide-nav-down 0.3s ease;
  transform: translateY(-100%);
  animation-fill-mode: forwards;
}

.navi-desktop.is-floating .navi-desktop__list-container {
  width: 100%;
  background: #fff;
  transition: width 300ms linear;
}


/**
 * ANIMATIONS
 */

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-enter-from{
  opacity: 0;
  height: 0;
}

.navi-desktop__sub {
}

/**
 * BREAKPOINT FOR MENU-WIDTH
 */
@media only screen and (min-width: 1280px) {
  .navi-desktop__logo {
    display: none;
  }
  .navi-desktop__list-container {
    width: auto;
  }
}

</style>