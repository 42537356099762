<template>
    <div id="auth-menu" class="flex-items-center">
        <div v-if="!user" id="auth-menu__logged-out" class="flex-group-start h100">
            <div class="auth-menu__login">
                <a @click="toggleLoginForm" href="#" id="auth-menu__login__login-link" :class="{'isActiveLoginForm': showLoginForm}" class="p3 bg-secondary-dark h100">
                    <i class="fas fa-user-circle"></i>
                    <!--<div class="auth-menu__login__login-link__title text-xl">Einloggen</div>--></a>
                <div v-show="showLoginForm" id="auth-menu__login__login-form" class="p4 box-shadow-gentle">
                    <slot></slot>
                    <!--
                                        <div v-html="login_form_html"></div>
                    -->
                </div>
            </div>
        </div>
        <div v-else id="auth-menu__logged-in" class="flex-group-sb">
            <div class="pos-relative">
                <button @click="showUserDropDown = !showUserDropDown" id="auth-menu__logged-in__user-button" :class="{'hasDropDown': showUserDropDown}" class="p3 white text-2xl flex-content-center">
                    <i class="fas fa-user-circle"></i>
                    <span class="ml1 inline-block text-lg p1">{{user.username}}</span>
                    <i class="fas fa-caret-down"></i>
                </button>
                <transition name="slideY">
                    <div v-show="showUserDropDown" class="auth-menu__logged-in__dropdown p2 bg-white">
                        <ul>
                            <li class="logged-in-link"><a class="p1 text-base" href="/profile"><i class="fas fa-user-cog"></i> Mein Profil</a></li>
                            <!--
                                                    <li><a class="p1 text-base" href="/activities"><i class="fas fa-chart-line"></i> Meine Aktivitäten</a></li>
                            -->
                            <li class="logged-in-link">
                                <form action="/logout" method="post" class="bg-white secondary p1 cursor-pointer flex-content-center" _token="csrf">
                                    <i class="fas fa-sign-out-alt"></i>
                                    <input type="hidden" name="_token" :value="csrf">
                                    <button type="submit" name="ausloggen" value="ausloggen" class="logged-in-link text-base secondary input-unstyled">Ausloggen</button>
                                </form>
                            </li>
                        </ul>
                    </div>
                </transition>

            </div>
        </div>

    </div>

</template>

<script>

    export default {
        props: ['user'],
        name: "auth-menu",
        data: function () {
            return {
                'showLoginForm': false,
                'showUserDropDown': false,
                'auth': this.user,
                /*
                                'login_form_html': null,
                */
                'csrf': document.querySelector('meta[name="csrf-token"]').getAttribute('content')

            }
        },
        computed: {
            isAnyOpen: function () {
                return this.showLoginForm === true || this.showUserDropDown === true;
            }
        },
        watch: {
            isAnyOpen (isOpen) {
                if (isOpen) {
                    document.addEventListener(
                        'click',
                        this.closeIfClickedOutside
                    );
                }
            }
        },
        methods: {
            toggleLoginForm () {
                this.showLoginForm = !this.showLoginForm;
            },
            closeIfClickedOutside (event) {
                if (!event.target.closest('#auth-menu')) {
                    this.showLoginForm = false;
                    this.showUserDropDown = false;
                    document.removeEventListener('click', this.closeIfClickedOutside);
                }
            },
        },
        mounted () {
            /*axios.get('/login-form').then(response => {
                this.login_form_html = response.data;
            })*/
        }
    }
</script>

<style lang="sass" scoped>

    .slideY-enter-active, .slideY-leave-active
        transition: all 0.25s ease
        opacity: 1

    .slideY-enter, .slideY-leave-to
        transform: translateY(-10px) !important
        opacity: 0



    #auth-menu
        height: 46px
        //background: #B22E2F
        color: #FFFFFF
        font-size: 1.25rem

        #auth-menu__login__login-link
            position: relative
            display: inline-block
            //width: 130px
            z-index: 9999
            color: #fff

            &:hover
                color: #ccc

        #auth-menu__login__login-link.isActiveLoginForm
            background: #fff
            color: #B22E2F

        #auth-menu__login__login-form
            position: absolute
            font-size: 12px
            background: #fff
            color: #999
            z-index: 99999
            right: 0

            input[type="text"]
                height: 2.5rem
                background: #ddd

        .auth-menu_login-button
            min-width: 6rem

        .auth-menu__register
            color: #B22E2F

            button
                color: #B22E2F

        a
            display: block
        #auth-menu__logged-in__user-button
            outline: none
        #auth-menu__logged-in__user-button.hasDropDown
            background: #fefefe
            color: #B22E2F
            transition: all 0.3s linear
        .auth-menu__logged-in__dropdown
            position: absolute
            width: 100%
            z-index: 99999

        .auth-menu__login__login-link__title
            display: none
        .logged-in-link
            &:hover
                color: #721E1E
                i
                    color: #721E1E
        @media only screen and (min-width: 360px)
            .auth-menu__login__login-link__title
                display: inline-block
</style>