<template>
  <div></div>
</template>

<script>




export default {
  name: "navi-base",
  data: function () {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      menu: {
        schreibtisch: [
          {
            titel: 'Mein Schreibtisch',
            icon: 'hi-desk',
            url: '/schreibtisch',
            description: 'Dein persönlicher Arbeitsbereich',
            show: this.$userData
          },
          {
            titel: 'Vokabelheft',
            icon: 'hi-vokabelheft',
            url: '/lerninhalte/vokabeln',
            description: 'Nicht gewusste Vokabeln ansehen',
            show: this.$userData
          },

          {
            titel: 'Zusatzmaterialien',
            icon: 'hi-zusatzinhalte',
            url: '/zusatzinhalte',
            description: 'Code für Zusatzmaterialien eingeben',
            show: this.$userData
          },


        ],
        ressourcen: [
          {
            titel: 'Dokumentation',
            icon: 'hi-dokumentation',
            url: 'https://docs.hermeneus.eu',
            description: 'Dokumentation für Nutzer, Redakteure und Entwickler',
            show: true,
          }
        ],
        lerninhalte: [
          {
            titel: 'Texte',
            icon: 'hi-texte-small',
            url: '/lerninhalte/texte',
            description: 'Texte mit dem Editor bearbeiten oder mit dem Reader lesen',
            show: this.$userData
          },
          {
            titel: 'Lerneinheiten',
            icon: 'hi-lerneinheit',
            url: '/lerninhalte/lerneinheiten',
            description: 'Lerneinheiten (Lektionen und WS-Sammlungen) verwalten',
            show: this.$userData
          },
          {
            titel: 'Übungen',
            icon: 'hi-uebungen',
            url: '/lerninhalte/uebungen',
            description: 'Übungen aus Lerneinheiten erstellen und durchführen',

            show: this.$userData
          },
          {
            titel: 'Grammatik',
            icon: 'hi-grammatik',
            url: '/lerninhalte/grammatik',
            description: 'Grammatikinhalte verwalten und anlegen',
            show: this.$userData && this.$userData.rights?.hasProficiency


          },

        ],
        werkzeuge: [
          {
            titel: 'Glossarium',
            icon: 'hi-glossarium-new',
            url: '/werkzeuge/glossarium',
            description: 'Wörter in der Datenbank nachschlagen und verwalten',
            show: this.$userData
          },
  /*        {
            titel: 'Georges 1913',
            icon: 'hi-dictionary',
            url: '/werkzeuge/georges',
            description: 'Ausführliches lateinisch-deutsches Handwörterbuch',
            show: this.$userData && (this.$userData.rights?.hasProficiency || this.$userData.rights?.accessAlphaFeatures)
          },*/
          {
            titel: 'Progressionsanalyse',
            icon: 'hi-progressionsanalyse',
            url: '/werkzeuge/progressionsanalyse',
            classes: 'alpha-feature',
            description: 'Einen Text auf bekannte Vokabeln und Grammatik überprüfen',
            show: this.$userData && (this.$userData.rights?.hasProficiency || this.$userData.rights?.accessAlphaFeatures)
          },
        ],
        site: [
          {
            titel: 'Über hermeneus',
            icon: 'hi-hermeneus',
            url: '/site/projekt',
            description: 'Was ist hermeneus und wie kam es dazu?',
          },
          {
            titel: 'Unterrichtseinsatz',
            icon: 'hi-board-draw',
            url: '/site/unterrichtseinsatz',
            description: 'Anwendungsbeispiele für den Lehrbuch- und Lektüreunterricht',
          },
          {
            titel: 'Hilfe & Support',
            icon: 'hi-support',
            url: '/site/support',
            description: 'Falls Fehler auftreten, finden Sie hier Hilfe.',
          },
          {
            titel: 'Datenschutzerklärung',
            icon: 'hi-kontakt-impressum',
            url: '/site/datenschutz',
            description: 'Welche Daten werden von Ihnen erhoben?',
          },
          {
            titel: 'Impressum & verwendete Inhalte',
            icon: 'hi-copyright',
            url: '/site/impressum',
            description: 'Verantwortlicher für den Inhalt & Querverweise',
          }
        ],
        redaktion: [
          {
            titel: 'Bibliothek',
            icon: 'hi-library',
            url: '/redaktion/bibliothek',
            description: 'Alle Bücher einsehen',
            show: this.$userData && this.$userData.rights?.manageBuecher

          },
          {
            titel: 'Codes verwalten',
            icon: 'hi-tessera',
            url: '/redaktion/codes',
            description: 'Tesserae und Freischaltcodes generieren und verwalten',
            show: this.$userData && this.$userData.rights?.manageBuecher

          },
        ],
        verwaltung: [
          {
            titel: 'Gruppenverwaltung',
            icon: 'hi-groups',
            url: '/verwaltung/groups',
            description: 'Benutzergruppen erstellen und verwalten',
            show: this.$userData && this.$userData.rights?.manageGroups
          },
          {
            titel: 'Tickets',
            icon: 'hi-jira',
            url: '/verwaltung/tickets',
            description: 'Tickets erstellen, um Fehler zu melden',
            show: this.$userData && this.$userData.rights?.manageGroups
          },

          {
            titel: 'Administration',
            icon: 'hi-sella',
            url: '/verwaltung/administration',
            description: 'Verwaltungsbereich für Administratoren und Editoren',
            show: this.$userData && this.$userData.rights?.viewAdminpanel
          },
        ],
        user: [
          {
            titel: 'Mein Profil',
            icon: 'hi-profile',
            url: '/profile',
            description: 'Benutzeraccount einsehen und ändern',
            show: this.$userData

          },

          {
            titel: 'Funktionen & Berechtigungen',
            icon: 'hi-checklist',
            url: '/profile/rights',
            description: 'Meine Benutzerrolle und Berechtigungen',
            show: this.$userData

          },
          {
            titel: 'Einloggen',
            icon: 'hi-login',
            url: '/login',
            description: '',
            show: !this.$userData
          },
          {
            titel: 'Registrieren',
            icon: 'hi-signup',
            url: '/register',
            description: 'Erstelle einen Account - natürlich kostenlos!',
            show: !this.$userData
          }
        ]
      },

    }
  },
  methods: {
    hideSubNavi () {
      this.activeSubNavi = null;
    },
    toggleSubNavi ($event, category) {
      this.activeSubNavi = null;
      this.activeSubNavi = category;
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>

</style>