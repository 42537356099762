/**
 * VUE3-SPECIFIC
 */
import {createApp, defineAsyncComponent} from 'vue/dist/vue.esm-bundler.js';
import LoadingDots from "../loader/LoadingDots.vue";
import HermeneusIcon from "../hermeneus.icons/HermeneusIcon.vue";
import IrisMessengerComponent from "../iris-messenger/components/IrisMessenger.vue";
import WebcodeNavigator from "../hermeneus.webcode-navigator/WebcodeNavigator.vue";
import Flash from "../iris-messenger/components/Flash.vue";
import LoadMore from "../load-more/LoadMore.vue";
import HermeneusFeedback from "../hermeneus.feedback/HermeneusFeedback.vue";
import NaviMobile from "../hermeneus.navi/NaviMobile.vue";
import AuthNavi from "../hermeneus.menu/AuthMenu.vue";
import HermeneusCollapsible from "../hermeneus.collapsible/HermeneusCollapsible.vue";
import LoadingDualRing from "../loader/LoadingDualRing.vue";
import LoadingDoubleBounce from "../loader/LoadingDoubleBounce.vue";
import {createPinia, PiniaVuePlugin} from 'pinia';


/**
 * MIXINS
 */
import subscriptionFeature from "./directives/subscriptionFeature.js";
import alphaFeature from "../hermeneus.vue-instance/directives/alphaFeature.js";
import advancedFeature from "../hermeneus.vue-instance/directives/advancedFeature.js";
import NaviBase from "../hermeneus.navi/NaviBase.vue";
import ModalOpener from "../hermeneus-modal/ModalOpener.vue";
import RootComponent from "../hermeneus.vue-instance/components/RootComponent.vue";
import NaviDesktop from "../hermeneus.navi/NaviDesktop.vue";
import {providesUserDataPlugin} from "../hermeneus.vue-instance/plugins/providesUserData.plugin.js";
import Reader from "../hermeneus.reader/js/components/Reader.vue";
import HermeneusMarkdownWrapper from "../hermeneus.markdown-wrapper/HermeneusMarkdownWrapper.vue";
/**
 * PLUGINS: THIRD PARTY
 */
import Buefy from "@ntohq/buefy-next";

import VueTippy from "vue-tippy";
import VueShepherdPlugin from "vue-shepherd";
import "tippy.js/themes/light.css";
import JsonEditorVue from 'json-editor-vue'
import PrimeVue from 'primevue/config';
import {usePassThrough} from "primevue/passthrough";
import Divider from 'primevue/divider';
import hermeneus from "../primevue/presets/hermeneus";
import Checkbox from "../primevue/presets/hermeneus/checkbox/index.js";
import {useIrisMessengerStore} from "../iris-messenger/IrisMessengerStore.js";
import ProgressBar from "../primevue/presets/hermeneus/progressbar/index.js";
import LoadingComet from "../loader/LoadingComet.vue";
import Paginator from "../primevue/presets/hermeneus/paginator/index.js";
import Button from "../primevue/presets/hermeneus/button/index.js";
import InputGroup from "../primevue/presets/hermeneus/inputgroup/index.js";
import PrimevueHermeneusPreview from "../primevue/presets/hermeneus/PrimevueHermeneusPreview.vue";
import GermanLocale from "../primevue/presets/hermeneus/locale_de.json";
import Radiobutton from "../primevue/presets/hermeneus/radiobutton/index.js";
import MultiStepConfiguratorComponent from "../components/MultiStepConfiguratorComponent.vue";

const GlobalVue = createApp(RootComponent);

GlobalVue.config.errorHandler = function (err, vm, info) {
    console.error(err, vm, info);
}


GlobalVue.component('wortart-adjektiv', defineAsyncComponent(() => import("../wortarten/WortartAdjektiv.vue")));
GlobalVue.component('wortart-eigenname', defineAsyncComponent(() => import("../wortarten/WortartEigenname.vue")));
GlobalVue.component('wortart-nomen', defineAsyncComponent(() => import("../wortarten/WortartNomen.vue")));
GlobalVue.component('wortart-numerale', defineAsyncComponent(() => import("../wortarten/WortartNumerale.vue")));
GlobalVue.component('wortart-partikel', defineAsyncComponent(() => import("../wortarten/WortartPartikel.vue")));
GlobalVue.component('wortart-pronomen', defineAsyncComponent(() => import("../wortarten/WortartPronomen.vue")));
GlobalVue.component('wortart-verb', defineAsyncComponent(() => import("../wortarten/WortartVerb.vue")));
GlobalVue.component('wortart-wendung', defineAsyncComponent(() => import("../wortarten/WortartWendung.vue")));


/**
 *
 */
GlobalVue.component('morph-table', defineAsyncComponent(() => import("../wortarten/MorphTable.vue")));
GlobalVue.component('morph-table-verb', defineAsyncComponent(() => import("../wortarten/MorphTables/MorphTableVerb.vue")));
GlobalVue.component('vocab-composer', defineAsyncComponent(() => import("../vocab-composer/VocabComposer.vue")));
GlobalVue.component('vokabellisten-library', defineAsyncComponent(() => import("../vokabellisten-library/VokabellistenLibrary.vue")));
GlobalVue.component('vokabellisten-creation-manager', defineAsyncComponent(() => import("../vokabellisten-creator/VokabellistenCreationManager.vue")));
GlobalVue.component('vokabellisten-editor', defineAsyncComponent(() => import("../vokabellisten-editor/VokabellistenEditor.vue")));
GlobalVue.component('vocab-analysis-scope-viewer', defineAsyncComponent(() => import("../vocab-analysis-scope-viewer/VocabAnalysisScopeViewer.vue")));
GlobalVue.component('grammatik-bestimmungen-selector', defineAsyncComponent(() => import("../grammatik-kategorien/GrammatikBestimmungenSelector.vue")));
GlobalVue.component('morpho-blocks', defineAsyncComponent(() => import("../grammatik-kategorien/MorphoBlocks.vue")));
GlobalVue.component('morpho-mapper', defineAsyncComponent(() => import("../morphomapper.js/vue/MorphoMapper.vue")));
/**
 * VOKABELLISTEN
 */
/**
 * GLOBAL COMPONENTS
 */

GlobalVue.component('loading-dots', LoadingDots);
GlobalVue.component('loading-double-bounce', LoadingDoubleBounce);
GlobalVue.component('loading-dual-ring', LoadingDualRing);
GlobalVue.component('loading-comet', LoadingComet);
GlobalVue.component('load-more', LoadMore);
GlobalVue.component('hermeneus-icon', HermeneusIcon);
GlobalVue.component('hermeneus-feedback', HermeneusFeedback);
GlobalVue.component('iris-messenger', IrisMessengerComponent);
GlobalVue.component('flash', Flash);
GlobalVue.component('modal-opener', ModalOpener);
GlobalVue.component('webcode-navigator', WebcodeNavigator);
GlobalVue.component('hermeneus-collapsible', HermeneusCollapsible);
GlobalVue.component('hermeneus-markdown-wrapper', HermeneusMarkdownWrapper);
GlobalVue.component('multistep-configurator', MultiStepConfiguratorComponent);

/**
 * AUTH / PROFILE
 */
GlobalVue.component('profile', defineAsyncComponent(() => import("../hermeneus.profile/HermeneusProfile.vue")));
GlobalVue.component('hermeneus-newsletter-activator', defineAsyncComponent(() => import("../hermeneus.profile/HermeneusNewsletterActivator.vue")));
GlobalVue.component('share-modal', defineAsyncComponent(() => import("../hermeneus.share/ShareModal.vue")));
GlobalVue.component('request-user-role', defineAsyncComponent(() => import("../request-user-role/RequestUserRole.vue")));
GlobalVue.component('hermeneus-contact', defineAsyncComponent(() => import("../hermeneus.contact/HermeneusContact.vue")));
GlobalVue.component('gravitas-counter', defineAsyncComponent(() => import("../hermeneus.gravitas-counter/GravitasCounter.vue")));
GlobalVue.component('hermeneus-tester-tickets-jira', defineAsyncComponent(() => import("../hermeneus.tickets-jira/HermeneusTesterTicketsJira.vue")));


/**
 * GLOSSARIUM
 */
GlobalVue.component('glossarium', defineAsyncComponent(() => import("../glossarium/Glossarium.vue")));
GlobalVue.component('glossarium-vocab-creator', defineAsyncComponent(() => import("../glossarium/GlossariumVocabCreator.vue")));
GlobalVue.component('glossarium-vocab-creator-suggest', defineAsyncComponent(() => import("../glossarium/GlossariumVocabCreatorSuggest.vue")));
GlobalVue.component('glossarium-vocab-suggestion-verifier', defineAsyncComponent(() => import("../glossarium/GlossariumVocabSuggestionVerifier.vue")));
GlobalVue.component('glossarium-vocab-creator-finished-loader', defineAsyncComponent(() => import("../glossarium/GlossariumVocabCreatorFinishedLoader.vue")));
GlobalVue.component('vocab-viewer', defineAsyncComponent(() => import("../vocab-viewer/VocabViewer.vue")));
GlobalVue.component('vocab-creator', defineAsyncComponent(() => import("../vocab-creator/VocabCreator.vue")));
GlobalVue.component('vocab-editor', defineAsyncComponent(() => import("../vocab-editor/VocabEditor.vue")));
GlobalVue.component('glossarium-quicksearch', defineAsyncComponent(() => import("../glossarium.quicksearch/GlossariumQuicksearch.vue")));

/**
 * ÜBUNGEN (EXTRACT)
 */
GlobalVue.component('uebungen-library', defineAsyncComponent(() => import("../uebungen-library/UebungenLibrary.vue")));
GlobalVue.component('uebung-creator', defineAsyncComponent(() => import("../uebung-creator/UebungCreator.vue")));
GlobalVue.component('uebung-show', defineAsyncComponent(() => import("../uebung-show/UebungShow.vue")));
GlobalVue.component('uebung-print', defineAsyncComponent(() => import("../uebung-print/UebungPrint.vue")));
GlobalVue.component('uebung-vokabelnlernen-flashcards', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnlernenFlashcards.vue")));
GlobalVue.component('uebung-vokabelnlernen-uncover', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnlernenUncover.vue")));
GlobalVue.component('uebung-vokabelnlernen-creator', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnlernenCreator.vue")));
GlobalVue.component('uebung-vokabelnlernen-print', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnLernenPrint.vue")));
GlobalVue.component('uebung-vokabelnlernen-maker', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnlernenMaker.vue")));
GlobalVue.component('uebung-vokabelnlernen-config', defineAsyncComponent(() => import("../uebung-vokabelnlernen/UebungVokabelnlernenConfig.vue")));
GlobalVue.component('uebung-bedeutungen-zuordnen-print', defineAsyncComponent(() => import("../uebung-bedeutungen-zuordnen/UebungBedeutungenZuordnenPrint.vue")));
GlobalVue.component('uebung-bedeutungen-zuordnen-creator', defineAsyncComponent(() => import("../uebung-bedeutungen-zuordnen/UebungBedeutungenZuordnenCreator.vue")));
GlobalVue.component('uebung-bedeutungen-zuordnen-maker', defineAsyncComponent(() => import("../uebung-bedeutungen-zuordnen/UebungBedeutungenZuordnenMaker.vue")));
GlobalVue.component('uebung-bedeutungen-zuordnen-dragndrop', defineAsyncComponent(() => import("../uebung-bedeutungen-zuordnen/UebungBedeutungenZuordnenDragndrop.vue")));
GlobalVue.component('uebung-bedeutungen-zuordnen-config', defineAsyncComponent(() => import("../uebung-bedeutungen-zuordnen/UebungBedeutungenZuordnenConfig.vue")));
GlobalVue.component('uebung-formen-bilden-creator', defineAsyncComponent(() => import("../uebung-formen-bilden/UebungFormenBildenCreator.vue")));
GlobalVue.component('uebung-formen-bilden-input', defineAsyncComponent(() => import("../uebung-formen-bilden/UebungFormenBildenInput.vue")));
GlobalVue.component('uebung-formen-bilden-maker', defineAsyncComponent(() => import("../uebung-formen-bilden/UebungFormenBildenMaker.vue")));
GlobalVue.component('uebung-formen-bilden-print', defineAsyncComponent(() => import("../uebung-formen-bilden/UebungFormenBildenPrint.vue")));
GlobalVue.component('uebung-formen-bilden-config', defineAsyncComponent(() => import("../uebung-formen-bilden/UebungFormenBildenConfig.vue")));
GlobalVue.component('uebung-formen-bestimmen-creator', defineAsyncComponent(() => import("../uebung-formen-bestimmen/UebungFormenBestimmenCreator.vue")));
GlobalVue.component('uebung-formen-bestimmen-picker', defineAsyncComponent(() => import("../uebung-formen-bestimmen/UebungFormenBestimmenPicker.vue")));
GlobalVue.component('uebung-formen-bestimmen-maker', defineAsyncComponent(() => import("../uebung-formen-bestimmen/UebungFormenBestimmenMaker.vue")));
GlobalVue.component('uebung-formen-bestimmen-print', defineAsyncComponent(() => import("../uebung-formen-bestimmen/UebungFormenBestimmenPrint.vue")));
GlobalVue.component('uebung-formen-bestimmen-config', defineAsyncComponent(() => import("../uebung-formen-bestimmen/UebungFormenBestimmenConfig.vue")));
GlobalVue.component('uebung-activitas-creator', defineAsyncComponent(() => import("../uebung-activitas/UebungActivitasCreator.vue")));
GlobalVue.component('uebung-activitas-print', defineAsyncComponent(() => import("../uebung-activitas/UebungActivitasPrint.vue")));
GlobalVue.component('uebung-activitas-config', defineAsyncComponent(() => import("../uebung-activitas/UebungActivitasConfig.vue")));
GlobalVue.component('ludi-romani', defineAsyncComponent(() => import("../ludiRomani/LudiRomaniSelectorView.vue")));
GlobalVue.component('cursus-print', defineAsyncComponent(() => import("../ludiRomani/cursus/LudiCursusPrint.vue")));
GlobalVue.component('uebung-cursus-activity', defineAsyncComponent(() => import("../ludiRomani/cursus/LudiCursusActivityView.vue")))

/**
 * LERNINHALTE (EXTRACT)
 */
GlobalVue.component('lerninhalte-picker', defineAsyncComponent(() => import("../lerninhalte-picker/LerninhaltePicker.vue")));
GlobalVue.component('lerneinheiten-library', defineAsyncComponent(() => import("../lerneinheiten-library/LerneinheitenLibrary.vue")));
GlobalVue.component('lerneinheiten-editor', defineAsyncComponent(() => import("../lerneinheiten-editor/LerneinheitenEditor.vue")));
GlobalVue.component('lerneinheiten-creator', defineAsyncComponent(() => import("../lerneinheiten-editor/LerneinheitenCreator.vue")));
GlobalVue.component('lerneinheiten-selector', defineAsyncComponent(() => import("../lerneinheiten.selector/LerneinheitenSelector.vue")));
GlobalVue.component('grammatik-selector', defineAsyncComponent(() => import("../grammatik.selector/GrammatikSelector.vue")));

GlobalVue.component('grammatik-library', defineAsyncComponent(() => import("../grammatik-library/GrammatikLibrary.vue")));
GlobalVue.component('grammatik-library-edit', defineAsyncComponent(() => import('../grammatik-library/GrammatikLibraryEdit.vue')));
GlobalVue.component('grammatik-library-create', defineAsyncComponent(() => import('../grammatik-library/GrammatikLibraryCreate.vue')));
GlobalVue.component('lerneinheiten-vocab-importer', defineAsyncComponent(() => import("../lerneinheiten-vocab-importer/LerneinheitenVocabImporter.vue")));

/**
 * TEXTE (EXTRACT)
 */
GlobalVue.component('text-library', defineAsyncComponent(() => import("../text-library/TextLibrary.vue")));
GlobalVue.component('textconfig-editor', defineAsyncComponent(() => import("../textconfig-editor/TextconfigEditor.vue")));
GlobalVue.component('text-publish-button', defineAsyncComponent(() => import("../hermeneus.text-publish-button/TextPublishButton.vue")));
GlobalVue.component('text-copy-dialog', defineAsyncComponent(() => import("../hermeneus.text-copy-dialog/TextCopyDialog.vue")));
GlobalVue.component('vocab-extraction', defineAsyncComponent(() => import("../text-tools/vocab-extraction/VocabExtraction.vue")));
GlobalVue.component('textverwaltung', defineAsyncComponent(() => import("../textverwaltung/Textverwaltung.vue")));
/**
 * HERMENEUS-READER
 */
GlobalVue.component('hermeneus-reader', Reader);
GlobalVue.component('dependency-tree-analysis', defineAsyncComponent(() => import("../hermeneus.deptree/DependencyTreeAnalysis.vue")));

/**
 * TEXTAUFBEREITUNG
 */
GlobalVue.component('text-aufbereitung', defineAsyncComponent(() => import("../text-creator/components/TextAufbereitung.vue")));


/**
 * VOKABELHEFT
 */
GlobalVue.component('vokabelheft', defineAsyncComponent(() => import("../hermeneus.vokabelheft/Vokabelheft.vue")));


/**
 * Administration
 */
GlobalVue.component('admin-panel', defineAsyncComponent(() => import("../administration/AdminPanel.vue")));

/**
 * GRUPPENVERWALTUNG
 */
GlobalVue.component('gruppenverwaltung', defineAsyncComponent(() => import("../gruppenverwaltung/Gruppenverwaltung.vue")));
GlobalVue.component('gruppenverwaltung-gruppe', defineAsyncComponent(() => import("../gruppenverwaltung/GruppenverwaltungGruppe.vue")));

/**
 * TICKETS
 *
 */
GlobalVue.component('hermeneus-tickets-jira', defineAsyncComponent(() => import("../hermeneus.tickets-jira/HermeneusTicketsJira.vue")));

/**
 * REDAKTION
 */
GlobalVue.component('code-management', defineAsyncComponent(() => import("../code-management/CodeManagement.vue")));
GlobalVue.component('buecher-library', defineAsyncComponent(() => import("../buecher-library/BuecherLibrary")));
GlobalVue.component('buecher-library-buch', defineAsyncComponent(() => import("../buecher-library/BuecherLibraryBuch")));
GlobalVue.component('buecher-library-create', defineAsyncComponent(() => import("../buecher-library/BuecherLibraryCreate")));


/**
 * ZUSATZINHALTE
 */
GlobalVue.component('zusatzinhalte', defineAsyncComponent(() => import("../zusatzinhalte/Zusatzinhalte.vue")));


const PresetConfigs = usePassThrough(hermeneus)


import AdminPanel from "@/administration/AdminPanel.vue";
import LoginForm from "@/auth/LoginForm.vue";
import LoginFormMin from "@/auth/LoginFormMin.vue";

// PRIMEVUE
GlobalVue.use(PrimeVue, {
    locale: GermanLocale.de,
    unstyled: true,
    pt: PresetConfigs,
    ptOptions: {
        mergeSections: false,
        mergeProps: true
    }

});
/*
* PRIMEVUE COMPONENTS
* Erstmal belassen, um zu sehen, welche Komponenten bereits angepasst wurden
 */
GlobalVue.component('primevue-hermeneus-preview', PrimevueHermeneusPreview)
GlobalVue.component('Divider', Divider)
GlobalVue.component('Paginator', Paginator)
GlobalVue.component('Button', Button)
GlobalVue.component('Checkbox', Checkbox)
GlobalVue.component('RadioButton', Radiobutton)
GlobalVue.component('ProgressBar', ProgressBar)
GlobalVue.component('InputGroup', InputGroup)

GlobalVue.use(VueTippy);
GlobalVue.use(JsonEditorVue);
GlobalVue.use(Buefy, {
    defaultIconPack: '',
});

GlobalVue.use(VueShepherdPlugin);
GlobalVue.use(PiniaVuePlugin);
/**
 * PLUGINS: EIGENE
 */
GlobalVue.use(providesUserDataPlugin);

/**
 * STORE-INTEGRATION
 */
const pinia = createPinia();
GlobalVue.use(pinia);
/**
 * IRIS MESSENGER
 */
GlobalVue.config.globalProperties.$IrisMessenger = useIrisMessengerStore();
window.$IrisMessenger = useIrisMessengerStore();
/**
 * DIRECTIVES
 */

GlobalVue.directive('subscription-feature', subscriptionFeature);
GlobalVue.directive('alpha-feature', alphaFeature);
GlobalVue.directive('advanced-feature', advancedFeature);


/**
 * NAVIGATION
 */
GlobalVue.component('navi-mobile', NaviMobile);
GlobalVue.component('navi-base', NaviBase);
GlobalVue.component('auth-menu', AuthNavi);
GlobalVue.component('navi-desktop', NaviDesktop);

/**
 * AUTHENTICATION
 */
GlobalVue.component('login-form', LoginForm);
GlobalVue.component('login-form-min', LoginFormMin);

/**
 * MOUNTING
 */
GlobalVue.mount('#vue_root');
window.GlobalVue = GlobalVue;