<template>
    <div>
        <div class="flex mx-auto w-fit mt-4 mb-5">
            <Button class="mr-5"
                    @click="previous()"
                    v-if="currentStepNumber > 0"
                    icon="fa-solid fa-chevron-left"/>
            <button class="text-xl"
                    :class="{ 'text-secondary-600': index === currentStepNumber }"
                    v-for="(component, index) in props.data.components"
                    :key="component.title"
                    :disabled="component.disabledInBreadcrumbs"
                    @click="currentStepNumber = index">
                {{ component.title }}
                <i v-if="index < props.data.components.length - 1"
                   class="fa-solid fa-chevron-right mx-5"></i>
            </button>
            <Button class="ml-5"
                    @click="next()"
                    v-if="currentStepNumber !== props.data.components.length - 1"
                    icon="fa-solid fa-chevron-right"/>
        </div>
        <div class="p-8">
            <component :is="dynComponents[currentStepNumber]"/>
        </div>
    </div>
</template>

<script setup>
import {defineAsyncComponent, shallowRef, onUnmounted, reactive, ref} from 'vue';
import {msConfigStore} from './stores/MultiStepConfiguratorStore';
import {EventBus} from '../hermeneus.eventbus/eventbus';
import Stepdata from './model/StepData.js';
import ComponentBean from './model/Component.js';

// Variablen
const props = defineProps(['data']);
const dynComponents = reactive({});
const currentStepNumber = ref(0);
const store = msConfigStore();
const customEvents = ref([]);

// Created Hook Ersatz.
// Die gibt's in der Composition API nicht mehr => Inhalt wird direkt in <script setup> notiert
// Als Prop übergene Components importieren ("registrieren")
// Struktur 'data.components' muss eingehalten werden, damit das Modal benutzt werden kann (dieses übergibt Props dynamisch mittels einer "data" Eigenschaft)
props.data.components.forEach((comp, i) => {
    if (!comp instanceof ComponentBean) throw new TypeError('MultiStepConfiguratorComponent: Die übergebenen Komponenten müssen vom Typ ComponentBean sein.');
    dynComponents[i] = shallowRef(defineAsyncComponent(() =>  import( /* @vite-ignore */ comp.componentPath)));
    if (comp.customEvents.length > 0) {
        comp.customEvents.forEach((event) => { // Dient v.a. Kompatibilitätsgründen mit bestehenden Komponenten: EventListener für mögliche CustomEvents in den verwalteten Komponenten registrieren; die vom Event mitgebrachten Daten werden im Store gesammelt. Man kann also in den Komponenten, die vom MSConfigurator verwaltet werden, entweder über Events Daten weitergeben oder den Store direkt manipulieren.
            customEvents.value.push(event);
            EventBus.$on(event, (data) => {
                store.addStep(new Stepdata(comp.title, data));
            });
        });
    }
});

EventBus.$on('activateStep', (stepTitle) => {
    props.data.components.forEach((comp, i) => {
        if (comp.title === stepTitle) {
            comp.disabledInBreadcrumbs = false;
        }
    });
});

// Methoden
function next() {
    if (currentStepNumber.value < props.data.components.length - 1) {
        currentStepNumber.value++;
    }
}

function previous() {
    if (currentStepNumber.value > 0) {
        currentStepNumber.value--;
    }
}

onUnmounted(() => {
    // Store für die nächste Verwendung der Komponente leeren
    // => Alle Daten, die im Rahmen der Konfig in den Store geschrieben wurden und später noch gebraucht werden, müssen vor dem Schließen des MultiStepConfigurators nach außen weitergegeben werden: z.B. mittels POST oder, falls der MultiStepConfigurator in einem Modal geöffnet wurde, indem das ModalCloseEvent erst in der Zielkomponente ausgelöst wird, nachdem die Daten aus dem Store gelesen wurden.
    store.$reset();
    customEvents.value.forEach((event) => {
        EventBus.$off(event);
    });
});

</script>

<style scoped></style>