<script>

import {IrisMessenger} from "@/iris-messenger/classes.js";
import NaviMobile from "@/hermeneus.navi/NaviMobile.vue";
import NaviBase from "@/hermeneus.navi/NaviBase.vue";
import NaviDesktop from "@/hermeneus.navi/NaviDesktop.vue";
import {opensVocabEditorMixin} from "@/hermeneus.vue-instance/mixins/opensVocabEditor.mixin.js";
import {providesGlobalConfigVariablesMixin} from "@/hermeneus.vue-instance/mixins/providesGlobalConfigVariables.mixin.js";
import AuthMenu from "@/hermeneus.menu/AuthMenu.vue";
import {hasIrisMessengerMixin} from "@/hermeneus.vue-instance/mixins/hasIrisMessenger.mixin.js";
import {interactsWithGlossariumMixin} from "@/hermeneus.vue-instance/mixins/interactsWithGlossarium.mixin.js";
import {EventBus} from "@/hermeneus.eventbus/eventbus.js";


/**
 * ROOT COMPONENT
 */
export default {
  mixins: [opensVocabEditorMixin, providesGlobalConfigVariablesMixin, hasIrisMessengerMixin, opensVocabEditorMixin, interactsWithGlossariumMixin],
  mounted() {

  },
};

</script>