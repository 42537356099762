<template>
    <div
            :style="`height: ${this.height}px`"
            class="webcode-navigator flex justify-center items-center">
        <div class="flex text-white italic text-3xl items-center">
           <div :class="{'hidden':this.description=='none'}"  class="webcode-navigator_s pt-1 mr-4 flex items-center">
               <i class="hi hi-tessera"></i>
           </div>
        </div>
        <input
                @keyup.enter="redirect"
                v-model="webcode_hash"
                :style="`height: ${this.height - 12}px`"
                type="text"
                class="webcode-navigator__input rounded-l focus:outline-none focus:shadow-outline"
                maxlength="5">
        <button @click="redirect"
                :style="`height: ${this.height - 12}px`"
                class="
                webcode-navigator__button
                rounded-r
                flex justify-center items-center
                p-1 bg-grey-300 text-grey-600 hover:text-secondary_color">
            <i class="fas fa-angle-right text-xl"></i>
        </button>
    </div>
</template>

<script>

    import axios from "axios";

    export default {
        name: "webcode-navigator",
        props: {
            description: {
                type: String,
                default: null,
            },
            height: {
                type: Number,
                default: 40,
            }
        },
        data: function () {
            return {
                'webcode_hash': '',
                'webcode_url': ''
            }
        },
        methods: {
            redirect () {
              axios.get(`/ad/validate/${this.webcode_hash.trim().toLowerCase()}`).then(
                  response => {
                    if (response.status === 200 || response.status == '200') {
                      window.open(`/ad/${this.webcode_hash.trim().toLowerCase()}`, '_blank');
                      this.webcode_url = response.data;
                      this.$IrisMessenger.bar({type: 'info', title: 'Weiterleitung', message: `Falls Sie nicht weitergeleitet wurden, <a href="/ad/${this.webcode_hash.trim().toLowerCase()}" target="_blank" class="bold text-underline">klicken Sie hier!</a>`})

                    } else {
                      this.$IrisMessenger.bar({type: 'danger', title: 'Webcode nicht gültig!', message: `Diesen Webcode gibt es leider nicht!`, selfDestructTimer: 2000})
                    }

                  }
              ).catch(error => {
                this.$IrisMessenger.bar({type: 'danger', title: 'Webcode nicht gültig!', message: `Diesen Webcode gibt es leider nicht!`, selfDestructTimer: 2000})

              })
            },
        }
    }
</script>

<style lang="sass" scoped>

    .webcode-navigator

        .webcode-navigator__input
            border: none
            background: #fff
            width: 5rem
            text-align: center
            letter-spacing: 3px
            text-transform: uppercase
            color: #191B28
            border-radius: 0.25rem 0 0 0.25rem

        .webcode-navigator__button
            border: none
            cursor: pointer

    .webcode-navigator_lg
        display: none
    .webcode-navigator_s
        display: inline-block
    .webcode-navigator_s.hidden
        display: none
    .webcode-navigator_lg.show
        display: inline-block
    .webcode-navigator_s.show
        display: inline-block

</style>
