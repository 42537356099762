export default class ComponentBean{
    
    props;
    title;
    componentPath;
    customEvents;
    disabledInBreadcrumbs; // Deaktiviert den direkten Aufruf dieser Komponente in den Breadcrumbs des MultiStepConfigurators. Kann sinnvoll sein, wenn die Komponente auf einer anderen, vorhergehenden Komponente aufbaut, die zwingend vor dieser aufgerufen werden muss.

    constructor(componentFullPath, title, customEvents = [], props = [], disabledInBreadcrumbs = false) {
        this.props = props;
        this.title = title;
        this.componentPath = componentFullPath;
        this.customEvents = customEvents;
        this.disabledInBreadcrumbs = disabledInBreadcrumbs;
    }
}