/**
 * Mixin, das zentrale Konfigurationsvariablen für alle Komponenten bereitstellt
 */
export let providesGlobalConfigVariablesMixin = {
    data: function () {
        return {
            GlobalConfigVariables: {}
        }
    },
    mounted: async function () {
        this.GlobalConfigVariables = window.GlobalConfigVariables;

    }
}