import {EventBus} from "../../hermeneus.eventbus/eventbus";

export let opensVocabEditorMixin = {
    mounted: function () {
        
        
        EventBus.$on('openVocabEditor', (vocab) => {
            this.openVocabEditor(vocab);
        });
        EventBus.$on('openVocabEditorAPI', (vocab) => {
            this.openVocabEditorAPI(vocab);
        });

    },
    
    methods: {
        /**
         * Opens VocabEditor.vue with open-modal-component
         * @param wort
         */
        openVocabEditor (wort) {
            let ModalData = {
                title: 'Vokabel bearbeiten: ' + wort.lemma,
                type: 'success',
                size: 'fullscreen',
                component: 'vocab-editor',
                appearance: {body: 'bg-grey-200'},
                uri: '/glossarium/' + wort.route_name + '/' + wort.id + '/edit',
            };
            EventBus.$emit('OpenModalEvent', JSON.stringify(ModalData));
        },
        /**
         * Opens VocabEditor.vue with open-modal-component
         * @param wort
         */
        openVocabEditorAPI (wort) {
            let ModalData = {
                title: 'Vokabel bearbeiten: ' + wort.lemma,
                type: 'success',
                size: 'fullscreen',
                component: 'vocab-editor',
                appearance: {body: 'bg-grey-200'},
                uri_api: '/glossarium/vocab/' + wort.route_name + '/' + wort.id + '/edit',
            };
            EventBus.$emit('OpenModalEvent', JSON.stringify(ModalData));
        },
        /**
         * Opens VocabEditor.vue with open-modal-component by Wortart & ID
         * @param wortart
         * @param id
         */
        openVocabEditorParams (wortart, id) {
            let ModalData = {
                title: 'Vokabel bearbeiten',
                type: 'success',
                size: 'fullscreen',
                component: 'vocab-editor',
                uri: '/glossarium/' + wortart + '/' + id + '/edit',
            };
            EventBus.$emit('OpenModalEvent', JSON.stringify(ModalData));
        },
    },

}