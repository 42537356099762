<template>
    <div class="w-auto">
        <ul class="navi-mobile__sub">
          <template v-for="link in links" >
            <li v-if="showLink(link)">
                <a :href="link.url" class="navi-mobile__sub-button">
                    <hermeneus-icon :name="link.icon" width="32" height="32" color="current"></hermeneus-icon>
                    <div class="ml-2" v-html="link.titel"></div>
                </a>
            </li>
          </template>
            <slot></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "navi-mobile-sub",
        props: ['links'],
        methods: {
            showLink (link) {
                if (link.hasOwnProperty('show')) {
                    return link.show
                }
                return true;
            },
            customHTML (link) {
                return link.hasOwnProperty('customHTML');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .navi-mobile__sub {
        @apply flex;
        @apply flex-col;
        @apply bg-primary-900;
        @apply text-xl;
    }

    *.navi-mobile__sub-button {
        @apply text-primary-300;
        @apply p-4;
        @apply flex;
        @apply flex-row;
        @apply items-center;
        @apply w-full;
    }

    *.navi-mobile__sub-button:hover {
        @apply text-primary-200;
        @apply bg-primary-800;
    }

</style>