import Helpers from "../helpers/class_helpers";
import { EventBus } from "../hermeneus.eventbus/eventbus.js";
import { reactive } from 'vue';

/**
 * Message Object
 * @type {IrisMessage}
 *
 */
export class IrisMessage {
    constructor(type = 'info', title = '', message = '', callbackFunction = null, countdown = null) {
        this.type = type;
        this.title = title;
        this.message = message;
        this.callback = callbackFunction;
        this.countdown = countdown;
        this.id = (Math.random() + 1).toString(36).substring(7);
    }
}

/**
 * IrisMessenger
 */
export class IrisMessenger {
    constructor() {
        this.messagesState = reactive({
            Messages: new Map()
        });

        EventBus.$on('IrisMessengerDisplay', (MessengerObject) => {
            this.show(MessengerObject);
        });
    }

    /**
     * Zerstört eine Nachricht nach einer in ms angegebenen gewissen Zeit (selfDestructTimer)
     * @param newMessage
     * @param selfDestructTimer
     */
    destruct(newMessage, selfDestructTimer) {
        setTimeout(() => {
            this.messagesState.Messages.delete(newMessage.id);
        }, selfDestructTimer);
    }

    show({ type, title, message, callbackFunction = null, selfDestructTimer = false }) {
        let newMessage = new IrisMessage(type, title, message, callbackFunction, selfDestructTimer);
        if (!this.messagesState.Messages.has(newMessage.id)) {
            this.messagesState.Messages.set(newMessage.id, newMessage);
            if (selfDestructTimer !== false) {
                this.destruct(newMessage, selfDestructTimer);
            }
        }
    }

    /***
     * Zeigt eine Nachricht an
     * :FIXME: Diese Methode funktioniert noch, soll aber durch die Methode show() ersetzt werden
     * @param type (success/info/danger/confirm)
     * @param title
     * @param message
     * @param callbackFunction
     * @param selfDestructTimer (in ms)
     */
    display(type, title, message, callbackFunction, selfDestructTimer = false) {
        let newMessage = new IrisMessage(type, title, message, callbackFunction, selfDestructTimer);
        if (!this.messagesState.Messages.has(newMessage.id)) {
            this.messagesState.Messages.set(newMessage.id, newMessage);
            this.updateMessagesState();
        }
        if (selfDestructTimer !== false) {
            this.destruct(newMessage, selfDestructTimer)
        }
    }

    updateMessagesState() {
        EventBus.$emit('IrisMessengerUpdate', this.messagesState.Messages);
    }
}